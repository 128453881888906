if (!window.onnit_context) {
    throw new Error("`window.onnit_context` is undefined.");
}

const { environment } = window.onnit_context;
const APP_ENV_IS_PROD = environment === "production";

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// This is NOT the place for secrets.
// Only PUBLIC values should be present here, since this is readable by the public.
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export default Object.freeze({
    // Alphabetical list.
    ALLIT_URL: window.onnit_context.url.allit,
    API_URL: window.onnit_context.url.api,
    // The environment in which the UI is being used. Can be "local", "staging", or "production".
    // NOT the same as NODE_ENV.
    APP_ENV: environment,
    APP_ENV_IS_LOCAL: environment === "local",
    APP_ENV_IS_PROD,
    COOKIE_DOMAIN: window.onnit_context.cookie_domain,
    COOKIE_DOMAIN_WILDCARD: window.onnit_context.cookie_domain_wildcard,
    FACEBOOK_APP_ID: "421787945392935",
    GOOGLE_MAPS_API_KEY: window.onnit_context.api_key.google_maps,
    GOOGLE_MAPS_SDK_URL: "https://maps.googleapis.com/maps/api/js",
    GOOGLE_RECAPTCHA_PUBLIC_KEY: "6LfDB7EqAAAAAMJrMQ67vAWSsYA6J8oOtgbg-FVE",
    GOOGLE_RECAPTCHA_SDK_URL: "https://www.google.com/recaptcha/api.js",
    IDME_CLIENT_ID: APP_ENV_IS_PROD
        ? "b347738075aff570c61a059cd76ffc01"
        : "be177bb09f5eab907eb99af6a8e56010",
    // The Webpack mode in which the UI bundle was built. Can be "development" or "production".
    // NOT the same as APP_ENV. These can differ in non-production environments b/c the built bundle should mimic production as closely as possible.
    NODE_ENV: process.env.NODE_ENV,
    NODE_ENV_IS_PROD: process.env.NODE_ENV === "production",
    NEW_EMAIL_SUBSCRIPTION_COUPON_ENABLED: false,
});
